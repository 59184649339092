// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const MUTATION = gql`
  mutation DossierTypeListHandleActionByBlockId(
    $actionId: ID!,
    $selectedIds: [ID!],
    $dossierId: ID!,
    $dossierTypeSlug: String!,
    $navigationSlug: String!,
    $context: String
  ) {
    successful: dossierTypeListHandleActionByBlockId(
      actionId: $actionId,
      ids: $selectedIds,
      dossierId: $dossierId,
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug,
      context: $context
    )
  }
`;

export default MUTATION;
